
export default {
  props: {
    onSubmit: { type: Function, required: true },
    additionalValidate: { type: Function, default: null },
  },

  methods: {
    async scrollToFirstError () {
      await this.$nextTick()
      const invalidEl = document.querySelector("[data-invalid]")
      invalidEl?.scrollIntoView({ behavior: "smooth" })
    },

    async validate () {
      let isValid = true

      const { observer } = this.$refs
      if (observer) isValid = await observer.validate()

      const { additionalValidate } = this
      if (additionalValidate) isValid = additionalValidate()

      if (!isValid) this.scrollToFirstError()

      return isValid
    },

    async onSubmit_ () {
      const isValid = await this.validate()
      if (!isValid) return
      await this.onSubmit()
      await this.scrollToFirstError()
    },

    reset () {
      this.$refs.observer?.reset()
    },
  },
}
