
import loginMixin from "@/mixins/login"

export default {
  mixins: [loginMixin],

  layoutProps: {
    titleToken: "login.title.login",
  },

  data: () => ({
    payload: {
      username: "",
      password: "",
    },
    apiErr: "",
    isLoading: false,
  }),

  watch: {
    payload: {
      handler () { this.apiErr = "" },
      deep: true,
    },
  },

  methods: {
    async onSubmit () {
      this.isLoading = true
      try {
        await this.$auth.loginWith("local", { data: this.payload })
        this.$router.push(this.localePath("/account"))
        this.$notifications.restart()
      } catch (e) {
        this.apiErr = e.response?.data || this.$t("errors.error")
      }
      this.isLoading = false
    },
  },
}
